import {
  CellId,
  DELETE_CELL,
  HexVersionAtomicOperation,
  HexVersionId,
  UPDATE_CELL,
} from "@hex/common";

import { hexVersionMPSelectors } from "../../../../redux/slices/hexVersionMPSlice";
import { RootState } from "../../../../redux/store.js";

/**
 * Helper to get the AO operations needed to eject a block cell
 */
export function getEjectBlockCellOps(
  cellId: CellId,
  hexVersionId: HexVersionId,
  state: RootState,
): HexVersionAtomicOperation[] {
  const cell = hexVersionMPSelectors
    .getCellSelectors(hexVersionId)
    .selectById(state, cellId);

  if (cell?.blockCellId == null) {
    throw new Error("could not get block cell");
  }

  const blockCellContents = hexVersionMPSelectors
    .getCellContentSelectors(hexVersionId)
    .selectByCellId(state, cellId);

  if (blockCellContents?.__typename !== "BlockCell") {
    throw new Error("expected block cell");
  }

  const childCellIds = hexVersionMPSelectors
    .getBlockCellSelectors(hexVersionId)
    .getBlockChildCellIds(state, cell.blockCellId);

  return [
    ...childCellIds.map((childCellId) => {
      return UPDATE_CELL.create(childCellId, "parentBlockCellId", null);
    }),
    DELETE_CELL.create({ cellId, skipToaster: true }),
  ];
}
